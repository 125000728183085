@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Wire+One&display=swap");
@font-face {
  font-family: Duke;
  src: url("../fonts/Duke Fill.otf") format("opentype");
}
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #333;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  background-color: #0c343d;
}

.desktop-logo {
  width: 75%;
  height: auto;
}

.app-menu {
  z-index: 10;
}

.desktop-menu-list ul {
  float: right;
  clear: right;
  display: flex;
  align-items: center;
}
.desktop-menu-list ul a {
  color: #fff;
  text-transform: uppercase;
  margin: 0 1em;
}
.desktop-menu-list ul a:last-child {
  margin-right: 0;
}
.desktop-menu-list ul a:hover {
  text-decoration: none;
}
.desktop-menu-list ul a li {
  list-style: none;
  display: flex;
  vertical-align: center;
  font-family: "Kanit";
  font-size: 1.25em;
}

.mobile-menu-btn {
  float: right;
}

.desktop-socials-menu-list ul {
  float: right;
  clear: right;
  display: flex;
  align-items: center;
  margin-bottom: 0.25em;
}
.desktop-socials-menu-list ul li {
  list-style: none;
  display: flex;
  vertical-align: center;
  margin: 0 0.45em;
}
.desktop-socials-menu-list ul li:last-child {
  margin-right: 0;
}

.main-wrapper {
  min-height: 100vh;
}

.main-panel-wrapper {
  z-index: -10;
  position: absolute;
  top: 0;
  right: 0;
}
.main-panel-wrapper.container-fluid {
  padding: 0;
}
.main-panel-wrapper .panel {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-right: 1px solid rgba(255, 255, 255, 0.2078431373);
  min-height: 100vh;
}
.main-panel-wrapper .panel:last-child {
  border-right: none;
}
.main-panel-wrapper .panel .panel-number-wrappper {
  margin-top: 20vh;
  padding-left: 1em;
}
.main-panel-wrapper .panel .panel-number-wrappper h1 {
  font-family: "Duke";
  color: #fff;
  font-size: 4.75em;
  margin-bottom: 0;
}
.main-panel-wrapper .panel .panel-number-wrappper .top-h1 {
  margin-top: 1.5em;
}
.main-panel-wrapper .panel .panel-number-wrappper .bottom-h1 {
  margin-top: -0.25em;
}
.main-panel-wrapper .panel .panel-number-wrappper ul {
  padding: 0;
}
.main-panel-wrapper .panel .panel-number-wrappper ul li {
  list-style: none;
  color: #fff;
  font-family: "Duke";
  font-size: 1.5em;
}
.main-panel-wrapper .panel .panel-number-wrappper .panel-number {
  color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.2705882353);
  font-family: "Kanit";
  font-size: 6em;
}

.servicesPage .panel {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-right: 1px solid rgba(255, 255, 255, 0.2078431373);
  min-height: 100vh;
  margin-bottom: 3em;
}
.servicesPage .panel:last-child {
  border-right: none;
}
.servicesPage .panel .panel-number-wrappper {
  margin-top: 2em;
  padding-left: 1em;
}
.servicesPage .panel .panel-number-wrappper h1 {
  font-family: "Duke";
  color: #fff;
  font-size: 4.75em;
  margin-bottom: 0;
}
.servicesPage .panel .panel-number-wrappper .top-h1 {
  margin-top: -0.5em;
}
.servicesPage .panel .panel-number-wrappper .bottom-h1 {
  margin-top: -0.25em;
}
.servicesPage .panel .panel-number-wrappper ul {
  padding: 0;
}
.servicesPage .panel .panel-number-wrappper ul li {
  list-style: none;
  color: #fff;
  font-family: "Duke";
  font-size: 1.5em;
}
.servicesPage .panel .panel-number-wrappper .panel-number {
  color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.2705882353);
  font-family: "Kanit";
  font-size: 6em;
}

.slick-dots li button:before {
  color: #fff !important;
}

.reel-single {
  position: relative;
  margin-top: 1em;
  padding: 0 1em;
  cursor: pointer;
}
.reel-single .reel-thumb {
  width: 100%;
  height: auto;
}
.reel-single .reel-details {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 1.5em;
}
.reel-single .reel-details h1 {
  color: #fff;
  font-family: "Kanit";
  font-size: 1em;
  text-transform: uppercase;
  margin-bottom: 0;
}
.reel-single .reel-details h2 {
  color: #fff;
  font-family: "Kanit";
  font-size: 1em;
}

.contact {
  margin-top: 1.75em;
  margin-bottom: 3em;
}
.contact.home {
  margin-top: 5em;
}
.contact.contact-us-page {
  margin-top: 0;
  padding-bottom: 1em;
}
.contact.contact-us-page h1 {
  color: #fff;
  -webkit-text-stroke: 1px #fff;
}
.contact h1 {
  text-align: center;
  color: transparent;
  color: #fff;
  -webkit-text-stroke: 1px #fff;
  font-family: "Kanit";
  font-size: 6em;
}

.MuiTextField-root .MuiFormLabel-root {
  color: #fff;
}
.MuiTextField-root .MuiFormLabel-root.Mui-focused {
  color: #fff;
}
.MuiTextField-root .MuiInputBase-input {
  color: #fff;
  font-family: "Kanit";
}
.MuiTextField-root .MuiInput-underline {
  border-bottom: 1px solid #fff;
}
.MuiTextField-root .MuiInput-underline:hover {
  border-bottom: 1px solid #fff;
}
.MuiTextField-root .MuiInput-underline:before {
  border-bottom: 1px solid #fff;
}
.MuiTextField-root .MuiInput-underline:after {
  border-bottom: 1px solid #fff;
}

.MuiFormHelperText-root.Mui-error {
  font-weight: bold;
  color: maroon !important;
}

.sbt-success {
  border: 1px solid green;
  border-radius: 0.25em;
  background-color: green;
  padding: 0.25em 0.5em;
  font-family: "Kanit";
  text-transform: uppercase;
  color: #fff;
}

.sbt-failure {
  border: 1px solid red;
  border-radius: 0.25em;
  background-color: red;
  padding: 0.25em 0.5em;
  font-family: "Kanit";
  text-transform: uppercase;
  color: #fff;
}

.btn-contact-message {
  background-color: #ff7f11;
  color: #fff;
  font-family: "Kanit";
  font-size: 1.25em;
  padding: 0.25em 1.25em;
}

.news-article {
  font-family: "Kanit";
  color: #fff;
}
.news-article a {
  color: #fff;
}
.news-article a:hover {
  text-decoration: none;
}

.about-content {
  font-family: "PT Sans";
  color: #fff;
}
.about-content p {
  font-size: 1.2em;
}

.awards-wrapper {
  background-color: #000;
  padding-bottom: 1.5em;
}
.awards-wrapper .awards-body {
  margin-top: 1.5em;
}
.awards-wrapper .awards-body .home-awards-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.awards-wrapper .awards-body .home-awards-wrapper .home-award-wrapper {
  width: 15%;
  margin: 0 1.75em;
}
.awards-wrapper .awards-body .home-awards-wrapper .home-award-wrapper .home-award-pic {
  width: 100%;
  height: auto;
}
.awards-wrapper .awards-body h1 {
  text-align: center;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  font-family: "Kanit";
  font-size: 6em;
}

.home-news-pic {
  width: 100%;
  height: auto;
}

.home-read-more-btn {
  margin-top: 2em;
}

.news-wrapper {
  margin: 2em 0 1em;
}
.news-wrapper .news-header {
  font-family: "Kanit";
  color: #fff;
}
.news-wrapper .news-content {
  display: flex;
  align-content: space-between;
  font-family: "PT Sans";
  color: #fff;
}
.news-wrapper .news-content p {
  font-size: 1.5em;
}

.link-pic {
  margin-right: 0.5em;
}

.link-href {
  color: #fff;
  font-family: "Kanit";
}
.link-href:hover {
  color: #fff;
  text-decoration: none;
}

.lights {
  margin-right: 0.5em;
}

footer {
  padding-top: 2em;
  background-color: #071f25;
}
footer .footer-img {
  width: 20%;
  height: auto;
}
footer h1 {
  color: #fff;
  font-family: "Kanit";
  font-size: 2em;
  font-weight: 100;
}
footer .footer-link-wrapper-1 {
  margin-bottom: 0;
}
footer .footer-link-wrapper-1 a {
  color: transparent;
}
footer .footer-link-wrapper-1 a :hover {
  text-decoration: none;
}
footer .footer-link-wrapper-1 p {
  display: inline;
  color: #fff;
  font-family: "Kanit";
  font-weight: 100;
  font-size: 1.5em;
  margin-bottom: 0;
}
footer .footer-link-wrapper-2 {
  margin-top: -1em;
}
footer .footer-link-wrapper-2 a {
  color: transparent;
}
footer .footer-link-wrapper-2 a :hover {
  text-decoration: none;
}
footer .footer-link-wrapper-2 p {
  display: inline;
  color: #fff;
  font-family: "Kanit";
  font-weight: 100;
  font-size: 1.5em;
}
footer .bz-con {
  margin-top: 1.5em;
  color: #fff;
  font-family: "Wire One";
  font-size: 1em;
  text-align: center;
  display: block;
}
footer .bz-con a {
  color: #fff;
}
footer .bz-con p {
  display: inline-block;
  margin-bottom: 0;
}
footer .bz-con .bz-con-logo {
  display: inline-block;
  margin: 0 0.5em;
}

.footer-socials-menu-list {
  margin-top: 1.2em;
}
.footer-socials-menu-list ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.25em;
  padding: 0;
}
.footer-socials-menu-list ul li {
  list-style: none;
  display: flex;
  vertical-align: center;
  margin: 0 0.45em;
}

.awards-slider {
  margin: 0 auto;
}
.home-award-wrapper {
  width: 20%;
  margin: 0 1.5em;
}

.awardsPage.awards-single {
  margin: 7.5em 0;
}
.awardsPage .awards-page-pic {
  max-width: 10em;
  max-height: 100%;
}

.secHeading {
  text-align: center;
  color: #fff;
  -webkit-text-stroke: 1px #fff;
  font-family: "Kanit";
  font-size: 6em;
}
.secHeading.fill {
  color: #fff;
}
.secHeading.minus {
  margin-top: -0.6em;
  margin-bottom: 0.75em;
}
.secHeading.awd {
  margin-bottom: 0.75em;
}

.video-wrapper h1 {
  text-transform: uppercase;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.3rem;
  background-color: #0a0a10;
}

.ModalClose {
  top: 0.5rem;
  right: 0.5rem;
  width: 3em;
  height: auto;
}
.ModalClose:hover {
  cursor: pointer;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.mobile-menu {
  display: none;
}

.close-mobile-menu-btn {
  float: right;
  padding-right: 1.2em;
}

.mobile-menu-list {
  padding-top: 1em;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 11000;
  transition: left 0.2s ease-in-out;
}
.mobile-menu-list.show {
  left: 0;
}
.mobile-menu-list.hide {
  left: 100%;
}
.mobile-menu-list ul {
  margin-left: none;
  text-align: end;
  margin-right: 1.5em;
}
.mobile-menu-list ul li {
  list-style: none;
  color: #fff;
  font-size: 1.75em;
  font-family: "PT Sans";
}

.app-container.maintenence {
  margin-top: 2em;
}
.app-container.maintenence .maintenence-content {
  margin-top: 5em;
  font-family: "PT Sans";
  color: #fff;
}

.not_found h1,
.not_found h2 {
  color: #fff;
  font-family: "PT Sans";
}

@media only screen and (max-width: 768px) {
  .mobile-menu {
    display: block;
  }
  .main-panel-wrapper {
    position: relative;
  }
  .app-menu {
    position: absolute;
  }
  .desktop-menu-list {
    display: none;
  }
  .desktop-socials-menu-list {
    display: none;
  }
  .contact h1 {
    font-size: 3em;
  }
  .awards-wrapper {
    background-color: #000;
  }
  .awards-wrapper .awards-body {
    margin: 1.25em 0;
    padding: 1.5em 0;
  }
  .awards-wrapper .awards-body .home-awards-wrapper .home-award-wrapper {
    width: 20%;
    margin: 0 1.5em;
  }
  .awards-wrapper .awards-body h1 {
    font-size: 3em;
  }
  .awardsPage .awards-page-pic {
    max-width: 5em;
  }
  .home-read-more-btn {
    text-align: center;
  }
  footer .footer-img {
    width: 60%;
  }
  footer h1 {
    font-size: 1.25em;
  }
  footer .footer-link-wrapper-1 p {
    font-size: 1em;
  }
  footer .footer-link-wrapper-2 {
    margin-top: 0;
  }
  footer .footer-link-wrapper-2 p {
    font-size: 1em;
  }
  .Modal {
    width: 100%;
    height: auto;
  }
  .app-container {
    padding-top: 6.5em;
  }
  .secHeading {
    font-size: 3em;
  }
  .secHeading.minus {
    margin-top: 1em;
  }
}/*# sourceMappingURL=style.css.map */