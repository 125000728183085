// @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@500&family=Mako&family=PT+Sans&family=Wire+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Wire+One&display=swap");

@font-face {
    font-family: Duke;
    src: url("../fonts/Duke\ Fill.otf") format("opentype");
}

$shadow-blue: #7d94bcff;
$space-cadet: #21273eff;
$baby-powder: #fffffaff;
$amber-sae-ece: #ff7f11ff;
$rich-black: #0a0a10ff;

$kanit: "Kanit";
$duke: "Duke";
$ptsans: "PT Sans";
$wireOne: "Wire One";

::-webkit-scrollbar {
    width: 7px;
}
::-webkit-scrollbar-track {
    background: #333;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body {
    background-color: #0c343d;
}

.desktop-logo {
    width: 75%;
    height: auto;
}

.app-menu {
    // position: absolute;
    z-index: 10;
}

.desktop-menu-list {
    ul {
        float: right;
        clear: right;
        display: flex;
        align-items: center;
        a {
            color: #fff;
            text-transform: uppercase;
            margin: 0 1em;
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                text-decoration: none;
            }
            li {
                list-style: none;
                display: flex;
                vertical-align: center;
                font-family: $kanit;
                font-size: 1.25em;
            }
        }
    }
}

.mobile-menu-btn {
    float: right;
}

.desktop-socials-menu-list {
    ul {
        float: right;
        clear: right;
        display: flex;
        align-items: center;
        margin-bottom: 0.25em;
        li {
            list-style: none;
            display: flex;
            vertical-align: center;
            margin: 0 0.45em;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.main-wrapper {
    min-height: 100vh;
}

.main-panel-wrapper {
    z-index: -10;
    position: absolute;
    top: 0;
    right: 0;
    &.container-fluid {
        padding: 0;
    }
    .panel {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        border-right: 1px solid #ffffff35;
        min-height: 100vh;
        &:last-child {
            border-right: none;
        }
        .panel-number-wrappper {
            margin-top: 20vh;
            padding-left: 1em;
            h1 {
                font-family: $duke;
                color: #fff;
                font-size: 4.75em;
                margin-bottom: 0;
            }
            .top-h1 {
                margin-top: 1.5em;
            }
            .bottom-h1 {
                margin-top: -0.25em;
            }
            ul {
                padding: 0;
                li {
                    list-style: none;
                    color: #fff;
                    font-family: $duke;
                    font-size: 1.5em;
                }
            }
            .panel-number {
                color: transparent;
                -webkit-text-stroke: 1px #ffffff45;
                font-family: $kanit;
                font-size: 6em;
            }
        }
    }
}

.servicesPage {
    .panel {
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        border-right: 1px solid #ffffff35;
        min-height: 100vh;
        margin-bottom: 3em;
        &:last-child {
            border-right: none;
        }
        .panel-number-wrappper {
            margin-top: 2em;
            padding-left: 1em;
            h1 {
                font-family: $duke;
                color: #fff;
                font-size: 4.75em;
                margin-bottom: 0;
            }
            .top-h1 {
                margin-top: -0.5em;
            }
            .bottom-h1 {
                margin-top: -0.25em;
            }
            ul {
                padding: 0;
                li {
                    list-style: none;
                    color: #fff;
                    font-family: $duke;
                    font-size: 1.5em;
                }
            }
            .panel-number {
                color: transparent;
                -webkit-text-stroke: 1px #ffffff45;
                font-family: $kanit;
                font-size: 6em;
            }
        }
    }
}

.slick-dots {
    li {
        button {
            &:before {
                color: #fff !important;
            }
        }
    }
}

.reel-single {
    position: relative;
    margin-top: 1em;
    padding: 0 1em;
    cursor: pointer;
    .reel-thumb {
        width: 100%;
        height: auto;
    }
    .reel-details {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0 1.5em;
        h1 {
            color: #fff;
            font-family: $kanit;
            font-size: 1em;
            text-transform: uppercase;
            margin-bottom: 0;
        }
        h2 {
            color: #fff;
            font-family: $kanit;
            font-size: 1em;
        }
    }
}

.contact {
    &.home {
        margin-top: 5em;
    }
    margin-top: 1.75em;
    margin-bottom: 3em;
    &.contact-us-page {
        margin-top: 0;
        padding-bottom: 1em;
        h1 {
            color: #fff;
            -webkit-text-stroke: 1px #fff;
        }
    }
    h1 {
        text-align: center;
        color: transparent;
        color: #fff;
        -webkit-text-stroke: 1px #fff;
        font-family: $kanit;
        font-size: 6em;
    }
}

.MuiTextField-root {
    .MuiFormLabel-root {
        color: #fff;
        &.Mui-focused {
            color: #fff;
        }
    }
    .MuiInputBase-input {
        color: #fff;
        font-family: $kanit;
        // font-weight: 500;
    }
    .MuiInput-underline {
        border-bottom: 1px solid #fff;
        &:hover {
            border-bottom: 1px solid #fff;
        }
        &:before {
            border-bottom: 1px solid #fff;
        }
        &:after {
            border-bottom: 1px solid #fff;
        }
    }
}

.MuiFormHelperText-root {
    &.Mui-error {
        font-weight: bold;
        color: maroon !important;
    }
}

.sbt-success {
    border: 1px solid green;
    border-radius: 0.25em;
    background-color: green;
    padding: 0.25em 0.5em;
    font-family: $kanit;
    text-transform: uppercase;
    color: #fff;
}

.sbt-failure {
    border: 1px solid red;
    border-radius: 0.25em;
    background-color: red;
    padding: 0.25em 0.5em;
    font-family: $kanit;
    text-transform: uppercase;
    color: #fff;
}

.btn-contact-message {
    background-color: $amber-sae-ece;
    color: #fff;
    font-family: $kanit;
    font-size: 1.25em;
    padding: 0.25em 1.25em;
}

.news-article {
    font-family: $kanit;
    color: #fff;
    a {
        color: #fff;
        &:hover {
            text-decoration: none;
        }
    }
}

.about-content {
    // display: flex;
    // align-content: space-between;
    font-family: $ptsans;
    color: #fff;
    p {
        font-size: 1.2em;
    }
}

.awards-wrapper {
    background-color: #000;
    padding-bottom: 1.5em;
    .awards-body {
        margin-top: 1.5em;
        .home-awards-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // overflow-x: hidden;
            .home-award-wrapper {
                width: 15%;
                margin: 0 1.75em;
                .home-award-pic {
                    width: 100%;
                    height: auto;
                }
            }
        }
        h1 {
            text-align: center;
            color: transparent;
            -webkit-text-stroke: 1px #fff;
            font-family: $kanit;
            font-size: 6em;
        }
    }
}

.home-news-pic {
    width: 100%;
    height: auto;
}

.home-read-more-btn {
    margin-top: 2em;
}

.news-wrapper {
    margin: 2em 0 1em;
    .news-header {
        font-family: $kanit;
        color: #fff;
    }
    .news-content {
        display: flex;
        align-content: space-between;
        font-family: $ptsans;
        color: #fff;
        p {
            font-size: 1.5em;
        }
    }
}
.link-pic {
    margin-right: 0.5em;
}

.link-href {
    color: #fff;
    font-family: $kanit;
    &:hover {
        color: #fff;
        text-decoration: none;
    }
}

.lights {
    margin-right: 0.5em;
}

footer {
    padding-top: 2em;
    background-color: #071f25;
    .footer-img {
        width: 20%;
        height: auto;
    }
    h1 {
        color: #fff;
        font-family: $kanit;
        font-size: 2em;
        font-weight: 100;
    }
    .footer-link-wrapper-1 {
        a {
            color: transparent;
            :hover {
                text-decoration: none;
            }
        }
        margin-bottom: 0;
        p {
            display: inline;
            color: #fff;
            font-family: $kanit;
            font-weight: 100;
            font-size: 1.5em;
            margin-bottom: 0;
        }
    }
    .footer-link-wrapper-2 {
        a {
            color: transparent;
            :hover {
                text-decoration: none;
            }
        }
        margin-top: -1em;
        p {
            display: inline;
            color: #fff;
            font-family: $kanit;
            font-weight: 100;
            font-size: 1.5em;
        }
    }
    .bz-con {
        // position: absolute;
        // width: 100%;
        // bottom: 0;
        // left: 50%;
        // transform: translateX(-50%);
        margin-top: 1.5em;

        a {
            color: #fff;
        }
        color: #fff;
        font-family: $wireOne;
        font-size: 1em;
        text-align: center;
        display: block;
        p {
            display: inline-block;
            margin-bottom: 0;
        }
        .bz-con-logo {
            display: inline-block;
            margin: 0 0.5em;
        }
    }
}

.footer-socials-menu-list {
    margin-top: 1.2em;
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.25em;
        padding: 0;
        li {
            list-style: none;
            display: flex;
            vertical-align: center;
            margin: 0 0.45em;
        }
    }
}

.reelsPage {
}

.awards-slider {
    // display: inline-flex;
    // justify-content: center;
    // align-items: center;
    margin: 0 auto;
    &.home-awa {
    }
}

.home-award-wrapper {
    width: 20%;
    margin: 0 1.5em;
}

.awardsPage {
    &.awards-single {
        margin: 7.5em 0;
    }
    .awards-page-pic {
        max-width: 10em;
        max-height: 100%;
    }
}

.secHeading {
    &.fill {
        color: #fff;
    }
    &.minus {
        margin-top: -.60em;
        margin-bottom: .75em;
    }
    &.awd {
        margin-bottom: 0.75em;
    }
    text-align: center;
    color: #fff;
    -webkit-text-stroke: 1px #fff;
    font-family: $kanit;
    font-size: 6em;
}

.video-wrapper {
    h1 {
        text-transform: uppercase;
    }
}

video {
    // max-width: 100em;
    // height: auto;
    // object-fit: fill;
}

.Modal {
    // z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // top: 5rem;
    // left: 10rem;
    // right: 10rem;
    // bottom: 6rem;
    border-radius: 0.3rem;
    background-color: $rich-black;
    // filter: invert(1) hue-rotate(180deg);
}

.ModalClose {
    // position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 3em;
    height: auto;
    // filter: invert(16%) sepia(0%) saturate(1976%) hue-rotate(150deg) brightness(90%) contrast(84%);
    &:hover {
        cursor: pointer;
    }
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.mobile-menu {
    display: none;
}

.close-mobile-menu-btn {
    float: right;
    padding-right: 1.2em;
}

.mobile-menu-list {
    padding-top: 1em;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0.95);
    z-index: 11000;
    transition: left 0.2s ease-in-out;
    &.show {
        left: 0;
    }
    &.hide {
        left: 100%;
    }
    ul {
        margin-left: none;
        text-align: end;
        margin-right: 1.5em;
        li {
            list-style: none;
            color: #fff;
            font-size: 1.75em;
            font-family: $ptsans;
        } // float: right;
    }
}

.app-container {
    &.maintenence {
        margin-top: 2em;
        .maintenence-content {
            margin-top: 5em;
            font-family: $ptsans;
            color: #fff;
        }
    }
}

.not_found {
    h1,
    h2 {
        color: #fff;
        font-family: $ptsans;
    }
}

@media only screen and (max-width: 768px) {
    .mobile-menu {
        display: block;
    }
    .main-panel-wrapper {
        position: relative;
    }
    .app-menu {
        position: absolute;
    }
    .desktop-menu-list {
        display: none;
    }
    .desktop-socials-menu-list {
        display: none;
    }
    .contact {
        h1 {
            font-size: 3em;
        }
    }
    .awards-wrapper {
        background-color: #000;
        .awards-body {
            margin: 1.25em 0;
            padding: 1.5em 0;
            .home-awards-wrapper {
                .home-award-wrapper {
                    width: 20%;
                    margin: 0 1.5em;
                }
            }
            h1 {
                font-size: 3em;
            }
        }
    }
    .awardsPage {
        .awards-page-pic {
            max-width: 5em;
        }
    }
    .home-read-more-btn {
        text-align: center;
    }
    footer {
        .footer-img {
            width: 60%;
        }
        h1 {
            font-size: 1.25em;
        }
        .footer-link-wrapper-1 {
            p {
                font-size: 1em;
            }
        }
        .footer-link-wrapper-2 {
            margin-top: 0;
            p {
                font-size: 1em;
            }
        }
    }
    .Modal {
        width: 100%;
        height: auto;
    }

    .app-container {
        padding-top: 6.5em;
    }
    .secHeading {
        &.minus {
            margin-top: 1em;
        }
        font-size: 3em;
    }
}
